<template>
	<div class="xinxiDialog">
		<div class="xinxitop">
			<a :href='IndexUrl'>二郎查一站式工具</a>
		</div>
		<div class="xinxiDialog-main">
			<div style="font-size:20px;color:#999;padding-bottom:10px;border-bottom:1px solid #ededed;">复审反馈结果</div></br>
			<div>广告计划id：{{content.value_id}}</div></br>
			<div v-html="content.content"></div>
		</div>
		
	</div>
</template>

<script>
export default {
	data () {
		return {
			adv_main_id:'',
			ticketId:'',
			content:'',
		}
	},

	mounted(){
		// console.log(this.$route.query.ticketId)
		this.ticketId = this.$route.query.ticketId
		this.adv_main_id = this.$route.query.adv_main_id
		this.ongetTicketDetailJs()
	},

	methods: {
		//获取工单详情
		ongetTicketDetailJs(ticketId){
			var prame = {
				ticketId:this.ticketId,
				adv_main_id:this.adv_main_id,
			}
			this.$service.get(this.$api.getTicketDetailJs,prame, (res)=> {
				if(res.code == 200){
					this.content = res.data
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.xinxiDialog{
	width: 750px;
	background: #fff;
	height: 100vh;
	overflow-x: hidden;
	padding:20px;
	margin: 0 auto;
	margin-bottom: 10px;
	box-sizing:border-box;
	.xinxitop{
		width: 100%;
		height: 60px;
		background: #f6f7f9;
		line-height: 60px;
		text-align: center;
		a{
			font-size: 24px;
			font-weight: 500;
			color: #333;
		}
	}
	.xinxiDialog-main{
		margin-top: 20px;
		div{
			font-size: 16px;
		}
	}
	img{
		max-width: 100%;
	}
	.title{
		color: #333;
		font-weight: 600;
	}
}
</style>
